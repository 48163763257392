import { API_URL, WS_SECURE_SYNC_HUB_ENDPOINT } from '@constants/index'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { useAppSelector } from '@store/hooks'
import { createContext, useContext, useEffect, useState } from 'react'
import { ProviderProps, SignalRWsContextType } from './SignalRWsTypes'

export const SignalRWsSecureContext = createContext<SignalRWsContextType>(null)

export default function SignalRWsSecureProvider({ children }: ProviderProps) {
  const { session } = useAppSelector((state) => state.auth)
  const userId = session?.email
  const token = session?.token

  const secureUrl =
    String(API_URL) +
    String(WS_SECURE_SYNC_HUB_ENDPOINT) +
    `?access_token=${String(token)}&userId=${String(userId)}`

  const [connection, setConnection] = useState<HubConnection>(null)
  const initConnection = (url: string) => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build()

    hubConnection
      .start()
      .then(() => {
        setConnection(hubConnection)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    initConnection(secureUrl)
  }, [secureUrl])

  return (
    <SignalRWsSecureContext.Provider value={connection}>
      {children}
    </SignalRWsSecureContext.Provider>
  )
}

export const useSignalRWsSecure = () => useContext(SignalRWsSecureContext)
