import { useSignalRWsSecure } from '@context/signalr/SignalRWsSecureContext'
import {
  CaptureSignalStatusMessage,
  CaptureSignalStatusType
} from '@models/CaptureSignalStatusMessage'
import { useCallback, useEffect, useState } from 'react'

export interface useCaptureSignalStatusReturnType {
  captureSignalStatus: CaptureSignalStatusMessage
}

const initialMessage: CaptureSignalStatusMessage = {
  signal: CaptureSignalStatusType.Stopped,
  reason: ''
}
export function useCaptureSignalStatus(): useCaptureSignalStatusReturnType {
  const hubConnectionContext = useSignalRWsSecure()

  const [captureSignalStatus, setCaptureSignalStatus] =
    useState<CaptureSignalStatusMessage>(initialMessage)

  const onCaptureSignalStatus = useCallback(() => {
    hubConnectionContext?.on(
      'CaptureSignalStatus',
      (connectionId: string, msg: CaptureSignalStatusMessage) => {
        setCaptureSignalStatus({
          signal: msg.signal ?? CaptureSignalStatusType.Stopped,
          reason: msg.reason
        })
      }
    )
  }, [hubConnectionContext])

  useEffect(() => {
    onCaptureSignalStatus()
  }, [onCaptureSignalStatus])

  return { captureSignalStatus }
}
