import { Capture, CaptureStatusType } from '@models/Capture'
import { ServiceArea } from '@models/ServiceArea'
import ToMap from '@shared/ToMap'
import { captureRequest } from '@store/dashboard/capture.slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { serviceAreaRequest } from '@store/serviceArea/serviceArea.slice'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useCaptureSignalStatus } from './useCaptureSignalStatus'

export interface UseCaptureControlType {
  capture?: Capture
  isCapturing?: boolean
  isLoading?: boolean
  error?: any
  serviceAreas: ServiceArea[]
  toggleStartCapture: boolean
  setToggleStartCapture: Dispatch<SetStateAction<boolean>>
  showPickSlide?: boolean
  setShowPickSlide?: Dispatch<SetStateAction<boolean>>
}

export default function useCaptureControl(): UseCaptureControlType {
  const dispatch = useAppDispatch()
  const { isCapturing, isLoading, error, capture } = useAppSelector(
    (state) => state.capture
  )
  const { serviceAreas } = useAppSelector((state) => state.serviceAreas)
  const { settings } = useAppSelector((state) => state.settings)

  const { captureSignalStatus } = useCaptureSignalStatus()

  const [toggleStartCapture, setToggleStartCapture] = useState(
    capture?.status === CaptureStatusType.Started
  )
  const [serviceAreasMap, setServiceAreasMap] =
    useState<Map<string, string>>(undefined)
  const [showPickSlide, setShowPickSlide] = useState(false)

  // useEffect(() => {
  //   if (isCapturing === true) {
  //     dispatch({
  //       type: 'notifications/add',
  //       payload: new Notification('You has been start to capture offers')
  //     })
  //   }

  //   dispatch({
  //     type: 'notifications/add',
  //     payload: new Notification('You has been stop to capture offers')
  //   })
  // }, [isCapturing])

  useEffect(() => {
    dispatch({
      type: 'capture/updateCaptureStatus',
      payload: captureSignalStatus
    })
  }, [captureSignalStatus, dispatch])

  useEffect(() => {
    dispatch({
      type: 'capture/updateRegionId',
      payload: settings?.profileSetting.regionId
    })
  }, [settings, dispatch])

  useEffect(() => {
    if (isLoading === true) {
      console.log('block control')
    }

    // if (captureSignalStatus.reason !== '') {
    //   setToggleStartCapture(capture?.status === captureSignalStatus.signal)
    // }

    // Error handling
    if (isLoading === false && error !== undefined) {
      setToggleStartCapture(capture?.status === CaptureStatusType.Started)
    }

    if (capture === undefined) dispatch(captureRequest(undefined))

    if (serviceAreas === undefined) {
      dispatch(serviceAreaRequest(settings?.profileSetting.regionId))
    }

    if (serviceAreas !== undefined) {
      setServiceAreasMap(
        ToMap(
          serviceAreas,
          (s) => s.identification,
          (s) => s.name
        )
      )
    }
  }, [isLoading, isCapturing, error, serviceAreas, capture, dispatch, settings])

  return {
    capture,
    isCapturing,
    isLoading,
    error,
    serviceAreas,
    toggleStartCapture,
    setToggleStartCapture,
    showPickSlide,
    setShowPickSlide
  }
}
