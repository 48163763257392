import { Switch } from '@headlessui/react'
import BIcon from './bIcon'
import { blocksIcons } from '@constants/icons'
import { useIntl } from 'react-intl'
import { ClassNames } from 'shared'

export default function BControlSwitch({
  className,
  label,
  onChange,
  checked,
  setEnabled
}: {
  className?: string
  label: string
  onChange?: any
  checked: boolean
  setEnabled?: any
}) {
  const { formatMessage: f } = useIntl()

  const onChangeHandle = (e: any) => {
    onChange(e)
    // setEnabled(e)
  }

  return (
    <div className={className}>
      <Switch
        checked={checked}
        onChange={onChangeHandle}
        className={ClassNames(
          checked && 'checked',
          'b-control-switch relative h-[58px] w-[150px] shrink-0 cursor-pointer rounded-[24px] border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75'
        )}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${
            checked ? 'justify-start' : 'justify-end pr-[24px]'
          } absolute flex item-center w-full h-full px-4 pt-[5px] transition-all`}
        >
          <p className={'text-left text-sm font-bold switch-text'}>
            {f({ id: 'capture' })}
            <br />
            {checked ? f({ id: 'active' }) : f({ id: 'inactive' })}
          </p>
        </span>

        <span
          aria-hidden="true"
          className={`${
            checked
              ? 'checked from-red-50 to-red-100'
              : 'from-green-200 to-green-300'
          } 
            btn-circle flex items-center justify-center pointer-events-none h-[50px] w-[57px] bg-white drop-shadow-md transform rounded-[21px] shadow-lg ring-0 transition duration-200 ease-in-out`}
        >
          <BIcon className="text-black" icon={blocksIcons.signal} size={20} />
        </span>
      </Switch>
    </div>
  )
}
