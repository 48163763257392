import BControlSwitch from '@components/bControlSwitch'
import SelectServiceAreasModal from '@components/ScheduleGenerals/SelectServiceAreasModal'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import { blocksIcons } from '@constants/icons'
import {
  ShieldCheckIcon,
  ShieldExclamationIcon
} from '@heroicons/react/20/solid'
import { Capture, CaptureStatusType } from '@models/Capture'
import { Input, Switch } from '@nextui-org/react'
import { ClassNames } from '@shared/DataValidation'
import { useAppDispatch } from '@store/hooks'
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useIntl } from 'react-intl'

export default function CaptureControlContainer({
  className = '',
  disabled = false,
  isOnlySettings = false,
  isInsulated = false,
  toggleStartCapture,
  capture,
  setToggleStartCapture,
  handleSubmit,
  onChange
}: {
  className?: string
  disabled?: boolean
  isOnlySettings?: boolean
  isInsulated?: boolean
  toggleStartCapture: boolean
  capture: Capture
  setToggleStartCapture: Dispatch<SetStateAction<boolean>>
  handleSubmit: (
    values: Capture,
    formikHelpers?: FormikHelpers<Capture>
  ) => void
  onChange?: (values: Capture, formikHelpers: FormikHelpers<Capture>) => void
}) {
  const { formatMessage: f } = useIntl()

  return (
    <div
      className={ClassNames(
        'capture-control-container w-full md:w-auto',
        className
      )}
    >
      <SubsectionWrapper className="w-full h-full">
        <div className="mb-4 flex gap-6">
          <p className="w-36 font-bold">{'Capture Control'}</p>
        </div>

        <Formik initialValues={capture} onSubmit={handleSubmit}>
          {({
            values,
            isSubmitting,
            submitForm,
            setFieldValue,
            handleChange
          }) => (
            <Form>
              <>
                {isOnlySettings && (
                  <AutoSubmit
                    capture={capture}
                    onChange={(x) => handleSubmit(x)}
                  />
                )}

                {!isOnlySettings && (
                  <BControlSwitch
                    checked={
                      toggleStartCapture ||
                      capture.status === CaptureStatusType.Started
                    }
                    onChange={async (e) => {
                      const status =
                        e === true
                          ? CaptureStatusType.Started
                          : CaptureStatusType.Stopped

                      setToggleStartCapture(e)
                      setFieldValue('status', status)
                      setFieldValue('regionId', capture.regionId)
                      await submitForm()
                    }}
                    label={f({ id: 'Capture Control' })}
                    className="capture-control flex items-center justify-between w-48 mb-5"
                  />
                )}

                <div className="filters-safe-mode mb-5 flex flex-col">
                  <label
                    htmlFor="insulated-filters-safe-mode"
                    className="form-label mb-1"
                  >
                    {f({ id: 'Safe Mode' })}
                  </label>

                  <Switch
                    disabled={isOnlySettings}
                    id="insulated-filters-safe-mode"
                    checked={capture.safeMode}
                    color="primary"
                    iconOn={<ShieldCheckIcon />}
                    iconOff={<ShieldExclamationIcon />}
                    onChange={(e) => {
                      setFieldValue('safeMode', e.target.checked)
                      // TODO: save
                    }}
                  />
                </div>

                {isInsulated && (
                  <>
                    <AutoSaveChange />
                    <div className="filters-service-areas mb-5">
                      <SelectServiceAreasModal
                        selected={values.serviceAreas}
                        onChange={(value: string[]) => {
                          setFieldValue('serviceAreas', value)
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="filters-min-amount mb-5">
                  <Input
                    disabled={disabled}
                    width="100%"
                    label={f({ id: 'Minimum amount' })}
                    type="number"
                    name="blockMinimumLength"
                    value={values.blockMinimumLength}
                    contentLeft={blocksIcons.money}
                    onChange={handleChange}
                    min="1"
                    max="1000"
                  />
                </div>

                <div className="filters-arrival-time mb-5">
                  <label className="form-label inline-block mb-1 pl-1">
                    {f({ id: 'Arrival time' })}
                  </label>
                  <div className="w-full grid grid-cols-2 gap-4">
                    <Input
                      disabled={disabled}
                      label={f({ id: 'min time' })}
                      type="time"
                      name="timeFilter.startTime"
                      value={values.startTime}
                      onChange={handleChange}
                    />
                    <Input
                      disabled={disabled}
                      label={f({ id: 'max time' })}
                      type="time"
                      name="timeFilter.endTime"
                      value={values.endTime}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </SubsectionWrapper>
    </div>
  )
}

function AutoSaveChange() {
  const dispatch = useAppDispatch()
  const { values } = useFormikContext<Capture>()

  useEffect(() => {
    console.log('form changes', values)
    dispatch({
      type: 'capture/updateCapture',
      payload: values
    })
  }, [values])
  return null
}

export function AutoSubmit({
  capture,
  onChange
}: {
  capture: Capture
  onChange: (x: any) => void
}) {
  const { values, submitForm, setValues } = useFormikContext()

  useEffect(() => {
    setValues(capture)
  }, [capture, setValues])

  useEffect(() => {
    void submitForm()
  }, [submitForm, values])

  return null
}
