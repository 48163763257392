import { ClassNames } from 'shared'
import { useTheme } from '@nextui-org/react'

// SectionWidget
export default function SectionWidget({
  children,
  className,
  flex = false,
  title,
  sideBtn
}: {
  children: React.ReactNode
  className?: string
  flex?: boolean
  title: string
  sideBtn?: React.ReactNode
}) {
  const { theme } = useTheme()

  return (
    <div
      className={ClassNames(
        className,
        'section-widget p-4 md:p-[30px] rounded-[30px] lg:rounded-[40px] shadow-xl'
      )}
      style={{
        background: theme?.colors?.accents3?.value
      }}
    >
      <div className="flex justify-between mb-4">
        <h4 className="text-xl font-bold">{title}</h4>
        {sideBtn}
      </div>
      <div
        className={ClassNames(
          flex ? 'flex space-x-14' : '',
          'section-widget-container'
        )}
      >
        {children}
      </div>
    </div>
  )
}
