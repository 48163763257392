import { ReactNode } from 'react'

export default function FormField({
  children,
  label
}: {
  children: ReactNode
  label: string
}) {
  return (
    <div className="form-field">
      {label !== undefined && (
        <label className="block mb-1.5 pl-1">{label}</label>
      )}
      {children}
    </div>
  )
}
