export default function ToMap<T, U>(
  array: T[],
  keyFn: (item: T) => string,
  valueFn: (item: T) => U
): Map<string, U> {
  return array.reduce((map, item) => {
    map.set(keyFn(item), valueFn(item))
    return map
  }, new Map<string, U>())
}
