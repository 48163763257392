import FormField from '@components/FormField'
import BButton from '@components/bButton/BButton'
import { Button, Checkbox, Modal, Text } from '@nextui-org/react'
import { useAppSelector } from '@store/hooks'
import { useState } from 'react'
import { useIntl } from 'react-intl'

export default function SelectServiceAreasModal({
  selected,
  onChange
}: {
  selected: string[]
  onChange: (value: string[]) => void
}) {
  const { formatMessage: f } = useIntl()
  const { serviceAreas } = useAppSelector((state) => state.serviceAreas)
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const [open, setOpen] = useState(false)

  // TODO: internationalize this
  const label = 'Service areas'
  const text = 'Select service areas'

  const closeHandler = () => {
    setOpen(false)
  }
  const openHandler = () => {
    setOpen(true)
  }

  const selectAllHandler = (isSelected: boolean): void => {
    if (!isSelected) {
      onChange(new Array<string>())

      setIsIndeterminate(false)
      setIsSelectedAll(false)
      return
    }

    onChange(
      serviceAreas.map((x) => {
        return x.identification
      })
    )

    setIsSelectedAll(true)
  }

  const selectedByGroup = (value: string[]): void => {
    if (isSelectedAll && !isIndeterminate) {
      setIsIndeterminate(true)
    }

    if (
      isSelectedAll &&
      isIndeterminate &&
      value.length === serviceAreas.length
    ) {
      setIsIndeterminate(false)
    }

    onChange(value)
  }

  return (
    <>
      <FormField label={label}>
        <BButton
          className="btn-open-service-areas-modal"
          text={text}
          onClick={openHandler}
          auto
          bordered
        />
      </FormField>

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={open}
        onClose={closeHandler}
        className="service-areas-modal"
      >
        <Modal.Header>
          <Text className="font-bold">{f({ id: 'Select service areas' })}</Text>
        </Modal.Header>
        <Modal.Body className="service-areas-modal-body min-h-[120px]">
          <div className="flex justify-between">
            <label>{f({ id: 'Service areas' })}</label>
            <Checkbox
              isIndeterminate={isIndeterminate}
              isSelected={isSelectedAll}
              onChange={selectAllHandler}
              size="xs"
            >
              {f({ id: 'Select all' })}
            </Checkbox>
          </div>

          <Checkbox.Group
            value={selected}
            onChange={selectedByGroup}
            className="service-areas-list max-h-64 overflow-hidden overflow-y-scroll"
          >
            {serviceAreas?.map((area) => (
              <Checkbox
                key={area.identification}
                value={area.identification}
                size="sm"
              >
                {area.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-close"
            onPress={closeHandler}
            auto
            color="error"
          >
            {f({ id: 'Close' })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
